/* tailwind fixes */
/* TODO: move these to beam-ui */
ul {
  list-style-type: disc;
  padding-left: 2.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

img {
  max-width: inherit;
  display: inline;
}

a {
  text-decoration-line: underline;
  --tw-text-opacity: 1;
  color: rgb(17 92 168 / var(--tw-text-opacity));
}
